/*
共通JS
*/

jQuery(function($){
  var win = $(window);
//----------------- ナビゲーション開閉
  $('#container').append('<div class="mask"></div>');
  $('#naviToggle').click(function(){
    $('body').toggleClass('open');
  });
  $('.mask').on('click', function(){
    $('body').removeClass('open');
  });
  // ドロップダウンメニュー
  if(win.width() < 800) {
    $('.dropdownBtn').on('click', function(){
      $(this).toggleClass('open');
      $(this).next('ul').slideToggle();
    });
  }

//----------------- タブ
  $('.tabNavi:not(.pageLink) li:first-child').addClass('select');
  $('.tabNavi:not(.pageLink) li').click(function() {
    var num = $(this).parent().children('li').index(this);
    $(this).parent('.tabNavi').each(function(){
      $('>li',this).removeClass('select').eq(num).addClass('select');
    });
    $(this).parent().next('.tabBoxes').children('div').hide().eq(num).show();
  }).first().click();

//----------------- タブレット・スマホだけで電話を掛けられるように処理
  var ua = navigator.userAgent;
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0){
    $('.telLink').each(function(){
      var str = $(this).html();
      $(this).html($('<a>').attr('href', 'tel:' + str.replace(/[^0-9]/g, '')).append(str + '</a>'));
    });
  }

//----------------- ページトップへ戻るのスクロール
  var $pagetop = $('#pageTop');

  $pagetop.hide();
  if (win.width() > 800) {
    win.scroll(function(){
      if(win.scrollTop() > 200){
        $pagetop.fadeIn();
      } else {
        $pagetop.fadeOut();
      }
    });
  }

  $pagetop.click(function(){
    $('html,body').animate({scrollTop:0},500);
    return false;
  });

  // ページ内アンカーリンクをスムーズにさせる&アンカーリンクへ飛んだ場合のヘッダー分のズレを解消(高さぞろえのjsの上に書くこと)
  function pagelink(heightnum){
    var headerHeight = heightnum;
    $('a[href^="#"]:not([class*="modal"])').click(function(){
      var href= $(this).attr("href");
      var target = $(href === "#" || href === "" ? "body" : href);
      var position = target.offset().top-headerHeight;
      $("html, body").animate({scrollTop:position}, 500, "swing");
      return false;
    });
    /* 別のページからアンカーリンクで飛ぶ場合 */
    var url = $(location).attr("href");
    if (url.indexOf("?id=") == -1) { //indexOf=～を含むか、含まなければ-1を返す
      //ここに含まない場合の記述
      } else {
      //ここに含む場合の記述
        var url_sp = url.split("?id=");//?id=を基準に分ける
        var hash = "#" + url_sp[url_sp.length - 1];//id?=を#にする。id=?="aaa"→#aaa
        var target2 = $(hash);
        var position2 = target2.offset().top-headerHeight;
        $("html, body").animate({scrollTop:position2}, 500, "swing");
      }
  }
  if(win.width() > 800) {
    pagelink(140);//ヘッダーの高さを入れる
  } else {
    pagelink(30);
  }

//----------------- 高さぞろえ
  $('#kitNavi li').matchHeight();
  $('.kitOrderDetail').matchHeight();
  $('.whiteBox p').matchHeight();
  $('.whiteBox').matchHeight();

//----------------- レスポンシブテーブル(複雑版)
  $('table.complicated').before("<div class='scrollable-info'></div>").wrap("<div class='wrap-scrolltable'></div>");

});
